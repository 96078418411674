<template>
 
  
  <div class="page">

    <UiInput
      :label="$t('forgot_password_view_email')"
      :text="email"
      :name="'email'"
      :locator="'forgot_password_view_email_locator'"
      @change:text="email = $event"
      />


    <NuxtTurnstile  ref="turnstile" v-model="token" />

    <div class="actions"> 
      <UiButton
        :name="$t('forgot_password_view_button_restore')"
        :type="'primary'"
        :locator="'forgot_password_view_button_restore_locator'"
        @click="login"
        :is-loading="isLoding"
        />        
      <UiButton
        :name="$t('universal_button_cancel')"
        :tag="'NuxtLink'"
        :type="'link'"
        to="/auth/signin"
        />          
    </div>  
 
    
  </div>  

</template>

<script setup lang="ts">

definePageMeta({
  layout: "short",
  auth: false
})

const email = ref('')
const router = useRouter();
const { $user, $popup, $api, $ga } = useNuxtApp();
const isLoding = ref(false)
const token = ref(null)
const turnstile = ref()

const validateEmail = (val) => {
  const re = /\S+@\S+\.\S+/
  return re.test(val)
}

const login = async () => {

  if (!validateEmail(email.value)) {
    $popup.error('Invalid email address')
    return
  }

  isLoding.value = true;
  $api
    .postForgotPasswordRequest(email.value, token.value)
    .then(() => {
      email.value = '';
      isLoding.value = false;
      $popup.success('Check your email for the password reset link');
      $ga.eventGA('user_forgot_password');
      // router.push('/')
    })
    .catch((err) => {
      turnstile.value?.reset();
      isLoding.value = false;
      $popup.error(err.message)
    })
}


</script>


<style scoped>
.page{
  padding: 2rem;
} 
.actions{
  /* margin-top: 10px; */
}
.actions > *{
  margin-right: 10px;
}
</style>
